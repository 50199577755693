import request from '@/services/request'
import apis from '@/services/apis'
import { roleCode } from '@/config'

export const getUsersByCompanyId = (id, isOnline) => {
  if (isOnline) {
    return new Promise((resolve, reject) => {
      request.get({
        url: `${apis.sharedUserBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${id}`,
      }).then(res => {
        if (res?.code === 1000) {
          const userList = (res?.data?.records || [])
          resolve(userList)
        }
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.get({
        url: `${apis.offlineOrganizationContact}?filter=sysOfflineOrganizationId : '${id}'&pageNumber=0&pageSize=9999`
      }).then(res => {
        if (res?.code === 1000) {
          const contactList = res.data.content
          resolve(contactList)
        }
      })
    })
  }
}

export const getVictoryCompanyCodeListReq = (orgId) => {
  return new Promise((resolve, reject) => {

    request.get({
      url: `${apis.getVictoryCustomer}?orgId=${orgId}`,
    }).then(res => {
      if (res?.code === 1000) {
        // CustomerNumber
        const arr = (res?.data || []).map(item=>{

            return { value:item.CustomerNumber, label:item.CustomerNumber,
              isActive:item.Status ===  "Active",
            }
        }).filter(el=> el.isActive)
        resolve(arr)
      }
    })
  })
}

export const getVictoryCompanyCodeListReqNoFilter = (orgId) => {
  return new Promise((resolve, reject) => {
    request.get({
      url: `${apis.getVictoryCustomer}?orgId=${orgId}`,
    }).then(res => {
      if (res?.code === 1000) {
        resolve(res?.data || [])
      }
    })
  })
}

export const getVictoryAccountSetListReq = () => {
  return new Promise((resolve, reject) => {
    request.get({
      url: apis.getVictoryAccountSet,
    }).then(res => {
      if (res?.code === 1000) {
        const arr = (res?.data || []).map(item=>{
          return {
            value:item.AccountSetKey,
            label:item.Description,
            isActive:item.Status ===  "Active",
          }
        })
        resolve(arr)
      }
    })
  })
}
export const getVictoryBillingCycleListReq = ( ) => {
  return new Promise((resolve, reject) => {
    request.get({
      url:apis.getVictoryBillingCycle,
    }).then(res => {
      if (res?.code === 1000) {
        const arr = (res?.data || []).map(item=>{
          return {
          value:item.BillingCycleKey,
          label:item.Description,
          isActive: item.Status === "Active",
        }
      })
        resolve(arr)
      }
    })
  })
}
export const getVictoryTermsListReq = ( ) => {
  return new Promise((resolve, reject) => {
    request.get({
      url:apis.getVictoryTerms,
    }).then(res => {
      if (res?.code === 1000) {
        const arr = (res?.data || []).map(item=>{
          return {
            value:item.TermsCode,
            label:item.Description,
            isActive:item.Status ===  "Active"
          }
        })
        resolve(arr)
      }
    })
  })
}

export const getJobSummaryCompletedJobs = (startDate, endDate, mock) => {
  mock = mock ?? false
  return new Promise((resolve, reject) => {
    request.get({
      url: `${apis.jobSummaryCompletedJobs}?startDate=${startDate}&endDate=${endDate}&mock=${mock}`,
    }).then(res => {
      resolve( res )
    }).catch(e => {
      reject(e)
    })
  })
}

export const getJobSummaryOilTypeQuantities = (startDate, endDate, mock) => {
  mock = mock ?? false
  return new Promise((resolve, reject) => {
    request.get({
      url: `${apis.jobSummaryOilTypeQuantities}?startDate=${startDate}&endDate=${endDate}&mock=${mock}`,
    }).then(res => {
      resolve( res )
    }).catch(e => {
      reject(e)
    })
  })
}

export const getJobSummaryLetterOfProtest = (startDate, endDate, mock) => {
  mock = mock ?? false
  return new Promise((resolve, reject) => {
    request.get({
      url: `${apis.jobSummaryLetterOfProtest}?startDate=${startDate}&endDate=${endDate}&mock=${mock}`,
    }).then(res => {
      resolve( res )
    }).catch(e => {
      reject(e)
    })
  })
}

export const getJobSummaryCompletedVessels = (startDate, endDate, mock, sortOrder) => {
  mock = mock ?? false
  sortOrder = sortOrder ?? 'desc'
  return new Promise((resolve, reject) => {
    request.get({
      url: `${apis.jobSummaryCompletedVessels}?startDate=${startDate}&endDate=${endDate}&sortOrder=${sortOrder}&mock=${mock}`,
    }).then(res => {
      resolve( res )
    }).catch(e => {
      reject(e)
    })
  })
}

export const getJobSummaryCompletedBarges = (startDate, endDate, mock, sortOrder) => {
  mock = mock ?? false
  sortOrder = sortOrder ?? 'desc'
  return new Promise((resolve, reject) => {
    request.get({
      url: `${apis.jobSummaryCompletedBarges}?startDate=${startDate}&endDate=${endDate}&sortOrder=${sortOrder}&mock=${mock}`,
    }).then(res => {
      resolve( res )
    }).catch(e => {
      reject(e)
    })
  })
}
